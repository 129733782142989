.navigation {
  /* Firefox */
  height: 9vh;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1vh;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  border-radius: 5px;

  .logo-container {
    width: 10%;

    .logo-image {
      height: 95%;
      overflow: hidden;
    }
  }

  .facility-name-container {
    margin-left: 1.0rem;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 2.0vh;
    font-weight: bold;
    color: #14254C;
    text-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    overflow: hidden;
  }

  .nav-links-container {
    height: 100%;
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #14254C;

    .nav-link {
      padding: 10px 15px;
      cursor: pointer;
      color: pink;
      font-size: 5vw;
    }
  }

  .signed-in-container {
    display: flex;
    flex-flow: column;
    margin: 1rem;
    width: 50%;
    text-align: right;

    .signed-in-as-container {
      display: flex;
      justify-content: space-between;
      font-size: 1.50vh;
      text-align: right;
      margin-top: 1.0rem;
    }

    .user-type {
      text-align: right;
      font-size: 1.50vh;
      color: darkgray;
      margin-top: 0.5rem;
      overflow: hidden;
    }

    .signed-in-as {
      font-size: 1.50vh;
      color: darkgray;
      margin-bottom: 0.1rem;
      overflow: hidden;
    }

    .sign-out-div {
      display: flex;
      justify-content: flex-end;
      overflow: hidden;
    }

    .sign-out-link {
      cursor: pointer;
      text-align: right;
      margin-top: .5rem;
      margin-bottom: .5rem;
      font-size: 1.50vh;
      overflow: hidden;
    }
  }
}