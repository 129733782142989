.react-calendar {
    width: 350px;
    max-width: 100%;
    background-color: RGBA(237,231,225,0.25);
    // background: #eaeaea;
    // border: 1px solid #a0a096;
    font-family: Helvetica, sans-serif;
    line-height: 1.0em;
    overflow: scroll;
  }
  
  
  .react-calendar--doubleView {
    width: 700px;
  }
  
  .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
  }
  
  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
  }
  
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
  }
  
  .react-calendar button:enabled:hover {
    cursor: pointer;
  }
  
  .react-calendar__navigation {
    display: flex;
    margin-bottom: 0.5em;
  }
  
  .react-calendar__navigation button {
    min-width: 34px;
    background: none;
  }
  
  .react-calendar__navigation button:disabled {
    background-color: #f0f0f0;
  }
  
  .react-calendar__navigation button:enabled:hover {
    background-color: #14254C;
    color: white;
    border-radius: 5px;
  }
  .react-calendar__navigation button:enabled:focus {
    background: none;
    color: #14254C;
    border-radius: 5px;
  }
  
  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.65em;
    color: grey;
  }
  
  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
    overflow: scroll;
  }
  
  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.65em;
    font-weight: bold;
  }
  
  .react-calendar__month-view__days__day--weekend {
    color: #d10000;
  }
  
  .react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
  }
  
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
  }
  
  .react-calendar__tile {
    max-width: 100%;
    padding: 10px 6.6667px;
    background: none;
    text-align: center;
    color: #14254C;
    overflow: hidden;
  }
  
  .react-calendar__tile:disabled {
    background-color: #f0f0f0;
  }
  
  .react-calendar__tile:enabled:hover {
    background-color: #14254C;
    color: white;
    opacity: 0.5;
    border-radius: 5px;
  }

  .react-calendar__tile:enabled:focus {
    background-color: #14254C;
    border-radius: 5px;
  }
  
  .react-calendar__tile--now {
    background: #f3f3f3;
  }
  
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: #ffffa9;
  }
  
  .react-calendar__tile--hasActive {
    color: white;
    background: #14254C;
    border-radius: 5px;
  }
  
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    color: white;
    background: #14254C;
    border-radius: 5px;
  }
  
  .react-calendar__tile--active {
    background: #14254C;
    color: white;
    border-radius: 5px;
  }
  
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #14254C
  }
  
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
}
  