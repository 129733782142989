root {
  --screen-height: 100vh;
  --screen-width: 100vw;
}

body {
  min-height: 100vh;
  min-width: 100vw;
  margin: 0 auto;
  min-height: 100vh;
  font-family: 'Helvetica';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
    background-color: transparent !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: #14254C;
}

* {
  box-sizing: border-box;
}
