.sign-in-container {
    width: 80%;
    color: #14254C;
    display: flex;
    margin-left: auto;
    margin-right: auto;

    .sign-in-row {
        display: flex;
        margin-top: 3.0vh;
        flex-direction: column;
        height: 5.0vh;
    }

    h2 {
        color: #14254C;
        margin: 10px 0;
    }

    .password-container {
        display: grid;
        width: 100%;
        grid-template-columns: 99% 1%;
        grid-template-rows: auto;
        grid-template-areas:
        "password eye";

        .passoword-form {
            grid-area: password;
        }

        .password-show-image {
            position: relative;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            grid-area: eye;
            width: 50px;
            height: 50px;
            margin-left: 2.0rem;
        }
    }

    .password-reset-and-resend-email-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        margin-top: 3.0vh;
        margin-bottom: 1.0vh;

        .password-reset {
            color: gray;
            cursor: pointer;
            text-align: center;
            margin-bottom: 1.5vh;
        }

        .resend-verifification-email {
            color: gray;
            cursor: pointer;
            text-align: center;
        }
    }
}